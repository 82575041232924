import { useEffect, useRef } from "react";
import { env } from "../utils/env";
import { identify, Identify, init, track } from "@amplitude/analytics-browser";

import { BaseTransport } from "@amplitude/analytics-core";
import { Payload, Response, Transport } from "@amplitude/analytics-types";

export class FetchTransport extends BaseTransport implements Transport {
  async send(serverUrl: string, payload: Payload): Promise<Response | null> {
    /* istanbul ignore if */
    if (typeof fetch === "undefined") {
      throw new Error("FetchTransport is not supported");
    }
    const options: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify(payload),
      method: "POST",
    };
    const response = await fetch(serverUrl, options);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const responsePayload: Record<string, any> = await response.json();
    return this.buildResponse(responsePayload);
  }
}

export const useAnalytics = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initTracking();
    }
  }, []);

  const initTracking = () => {
    if (env("NODE_ENV") !== "test") {
      init(env("REACT_APP_AMPLITUDE"), "", {
        serverUrl: `${env("REACT_APP_API_BASE_URL")}/api/v1/amplitude`,
      });
      initialized.current = true;
    }
  };

  const setAmpUserId = (id: string | null) => {
    const identifyObj = new Identify();
    if (id === null) {
      identifyObj.set("userId", "");
      identify(identifyObj);
    }
    if (!id || id.length < 1) {
      return;
    }
    identifyObj.set("userId", id);
    identify(identifyObj);
  };

  const trackAmpEvent = track;
  const trackAmpIdentity = (key: string, value: string) => {
    const identifyObj = new Identify();
    identifyObj.set(key, value);
    identify(identifyObj);
  };

  const trackGAPage = (page: string) => {
    // @ts-ignore
    if (window?.analytics?.page) {
      // @ts-ignore
      window?.analytics?.page(page);
    }
  };

  return {
    initTracking,
    setAmpUserId,
    trackAmpEvent,
    trackAmpIdentity,
    trackGAPage,
  };
};
